.press
  color: #3b3b3b
  padding-bottom: 3px
  h3
    font-size: 36px
    letter-spacing: 5px
    padding: 22px 0
    margin: 24px 0
    border-top: 2px solid #3b3b3b
    border-bottom: 2px solid #3b3b3b
    text-align: center
    font-family: "proxima-nova", sans-serif
    font-weight: normal
  ul
    list-style-type: none
    text-align: center
    li
      float: left
      display: inline-block
      margin: 10px
      strong
        display: block
        font-size: 20px
        color: #3b3b3b
        font-weight: normal
        padding: 5px 0
        border-top: 1px solid #3b3b3b
        border-bottom: 1px solid #3b3b3b
        margin-top: 9px
        letter-spacing: 2px
        font-family: "proxima-nova", sans-serif
