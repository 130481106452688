/** Generated by FG *

input, button, select, textarea, .edit-btn
  font-family: "proxima-nova",sans-serif

#breadcrumbs
  a, span
    font-family: "proxima-nova",sans-serif

.wall .description h2
  font-family: "proxima-nova",sans-serif

.accordion1
  .accordion-heading a, .accordion-body p
    font-family: "proxima-nova",sans-serif

.checkout .btn-red
  font-family: "proxima-nova",sans-serif

.shopping-cart
  h2, ul li p
    font-family: "proxima-nova",sans-serif

.form2
  font-family: "proxima-nova",sans-serif

  li, .required-fields
    font-family: "proxima-nova",sans-serif
.popup1
  h2, p
    font-family: "proxima-nova",sans-serif

.form2 .btn-red
  font-family: "proxima-nova",sans-serif

#shopping-cart-popup
  .checkout
    font-family: "proxima-nova",sans-serif

.infobar p
  font-family: "proxima-nova",sans-serif

.btn-red, .list1 li span.metadata, #my-works .header p a, #my-profile .user-info h2, .image-size, .list1 li .description h3, #my-profile .user-info p a, #search-filter h2.title
  font-family: "ff-meta-serif-web-pro",serif

.page-title h2
  font-family: "ff-meta-serif-web-pro",serif

/*{font-family: "calluna",serif;}
