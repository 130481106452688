/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, vendor/assets/stylesheets,
 * or vendor/assets/stylesheets of plugins, if any, can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the top of the
 * compiled file, but it's generally better to create a new file per style scope.
 *
 *= require_self
 */


@import 'bootstrap-2.3.2/css/bootstrap.css'

@import '../../../../vendor/legacy/bootstrap-modal'
@import './screen.sass'
@import './typography.sass'
@import './works.sass'
@import './wall.sass'
@import './users.sass'
@import './checkout.sass'
@import './cloudzoom.sass'
@import './orders.sass'
@import './hpv2.scss'
@import './edit_works.scss'
@import './press.sass'
@import './store_status.sass'
@import './popups.sass'
@import './agent_reports.sass'
@import './boutiques.sass'
@import './collections.sass'
@import './weekly_sale.sass'
@import './basket_unsubscribes.sass'
@import './user_history.sass'
@import './navigation.scss'
@import './sign_in_screens.scss'
@import './profile_v2.scss'
@import './cms_page.scss'

@import '../../../../vendor/fontawesome/fontawesome.css'

*
  font-display: swap

.hidden
  display: none

*, :after, :before
  box-sizing: border-box


.modal-open
  position: static


[class^="icon-"], [class*=" icon-"]
  background-image: none

body
  margin: 0px
  min-height: 100%
  height: auto !important

.link_btn
  line-height: 27px

.image
  cursor: pointer

#profile-edit-form
  .loading
    top: 60px
    left: 60px
    position: absolute
  h3
    margin-top: -16px

#progress_bar
  margin-top: 20px

#forgot-password-form
  ul
    background: url('./images/theme/border-bg1.png') repeat-x 0 bottom
    margin: 0 0 39px
    padding-bottom: 1px
    li
      width: 545px
      span
        padding-left: 8px
        margin-right: 8px
  p
    font-size: 14px
    line-height: 18px
    a
      color: #ec4b4a
      font-size: 14px
      text-decoration: underline
      &:hover
        text-decoration: none

.ui-slider
  float: left
  position: relative
  width: 295px
  height: 11px
  margin: 0 10px
  background: #bebebe
  top: 5px
  margin: 0 20px
  .ui-slider-handle
    width: 27px
    height: 27px
    background: url("./images/theme/slider-handle.png") no-repeat 0 0
    left: 60%
    top: -7px
    z-index: 200
    display: block
    position: absolute
    margin-left: -14px

.help-inline
  color: red

.base_error
  background-color: red
  margin: 0 -10px 10px
  padding: 10px
  color: white

.slide
  margin-left: 15px

#login-form
  ul
    background: url("./images/theme/border-bg1.png") repeat-x 0 bottom
    margin: 0 0 39px
    padding-bottom: 1px
    li
      width: 545px
      span
        padding-left: 8px
        margin-right: 8px
  p
    font-size: 14px
    line-height: 18px
    a
      color: #ec4b4a
      font-size: 14px
      text-decoration: underline
      &:hover
        text-decoration: none
  .checkbox
    input[type="checkbox"]
      margin-left: -8px
      margin-right: 6px
  .value
    color: #ec4b4a
  .last
    background: url("./images/theme/border-bg1.png") repeat-x 0 bottom
    padding-bottom: 15px
  a
    color: #ec4b4a
    text-decoration: underline
  h3
    font-size: 20px
    small
      line-height: 37px

.my_account_header
  h3
    display: inline-block
  a
    display: inline-block
    margin-left: 20px

#artist_profit
  font-weight: bold

.profit_value
  font-weight: bold
  width: 150px !important
  border: 1px solid rgb(190, 190, 190)
  padding: 7px
  font-size: 14px

#short-bio, #statment
  .accordion-inner
    p
      margin: 0 0 10px

.step
  font-size: 14
  font-weight: bold

nav.pagination
  li a
    background: #f5f5e4
    color: #333
    font-family: proxima-nova, sans-serif
    &:hover
      background: #fff
  float: left


.container
  position: relative
.wall.flat
  margin-bottom: 0
  background: none
  &.wall-center
    max-width: 940px
    margin: 9px auto 0 auto

#walls-c
  margin-bottom: 10px
  margin-left: 0
  a.next-page, a.prev-page, a.n-page, a.p-page
    position: absolute
    height: 98px
    width: 71px
    font-size: 220px
    top: 147px
    text-decoration: none
    opacity: 0.8
    color: #aaa
    display: block
    line-height: 111px
    z-index: 701
    font-weight: 100
    -moz-transition: opacity 0.3s
    -o-transition: opacity 0.3s
    -ms-transition: opacity 0.3s
    -webkit-transition: opacity 0.3s
    transition: opacity 0.3s
    display: none
    &:hover
      opacity: 1
  a.next-page, a.n-page
    left: auto
    right: 0
  a.prev-page, a.p-page
    right: auto
    left: 0
  a.p-page, a.n-page
    display: inline

@media (min-width: 1300px)
  #walls-c
    a.prev-page, a.p-page
      left: -65px
    a.next-page, a.n-page
      right: -65px


.wall.wall-center
  .wall-keeper
    margin: 0 auto
    position: relative
    width: 920px
    height: 552px
    .carousel-indicators
      right: auto
      left: 15px
    .wall-image
      width: 920px
      height: 552px
      cursor: pointer
    a.carousel-control
      border: none
      background: transparent
      top: 53% !important
    .view-unframed-price
      margin-right: 12px
      label
        font-size: 12px
        color: #8e8e8a
      input
        margin: 0

.wall-align, .work-align, .wall-images
  .pull-left
    margin-right: 3px
  .divider
    display: inline-block
    width: 2px
    height: 20px
    width: 0
    margin: 0 3px
    border-left: 1px solid #aaa

.work-align .pull-left
  line-height: 7px

.wall-align
  width: 920px
  margin: 0 auto 16px auto
  &.top-user-toolbar
    margin-top: 9px
  h3
    margin: 16px
    font-size: 14px
    font-weight: normal
    a
      float: right
      color: #333
      font-size: 12px
      white-space: nowrap
  ul.walls
    margin: 0 0 12px 0
    padding: 0 6px
    list-style: none
    li
      float: left
      width: 290px
      margin: 0 6px
      padding: 0
      background: #fff
      position: relative
      -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.13)
      -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.13)
      -o-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.13)
      -ms-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.13)
      box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.13)
      p
        padding: 12px 12px 6px 12px
        font-family: "ff-meta-serif-web-pro", serif
        span
          display: inline-block
          width: 162px
          height: 20px
          overflow: hidden
          text-overflow: ellipsis
          -o-text-overflow: ellipsis
          -ms-text-overflow: ellipsis
          display: block
        a.remove-favorite
          position: absolute
          right: 5px
          bottom: 20px
        a.shop_wall
          border: 1px solid #ec4b4a
          color: #ec4b4a
          display: block
          padding: 3px 3px 1px 3px
          font-size: 12px
          position: absolute
          bottom: 12px
          right: 10px
          font-family: proxima-nova, sans-serif
          &:hover
            text-decoration: none
        a.buy-it
          display: block
          border: none
          color: #8e8e8a
          text-decoration: underline
          float: left
          padding-left: 0

      .keeper
        width: 266px
        height: 266px
        line-height: 266px
        overflow: hidden
        position: relative
        border: 12px solid #fff
        border-bottom: none
        background: none
        a
          position: absolute
          top: 0
          left: -88px
          display: block
          height: 266px
          width: 443px
          img
            width: 443px

.static-page
  font-size: 11pt
  margin-bottom: 40px
  min-height: 400px
  .offset-anchor
    padding-top: 110px
  .underline
    text-decoration: underline
  ol
    counter-reset: listing

    li
      margin: 7px
      display: block

  ol
    li:before
      content: counters(listing, ".") ".    "
      counter-increment: listing

  h5
    margin-bottom: 0

  .table-condensed
    font-size: 13px

  .strong
    font-weight: bold

  .video-banner
    margin: 0 auto
    width: 75%
    @media (max-width: 1400px)
      width: 75%
    @media (max-width: 1100px)
      width: 100%
    video
      width: 100%
      margin: 0 auto

.reorder-btn
  visibility: hidden
  display: inline-block
  padding: 3px 7px
  font-size: 12px
  color: #666
  margin-left: 10px
  background: #eee
  cursor: move
.description:hover
  .reorder-btn
    visibility: visible
.artist-works
  .ui-sortable-helper
    box-shadow: 0 8px 8px -5px #999
    -moz-box-shadow: 0 8px 8px -5px #999
    -webkit-box-shadow: 0 8px 8px -5px #999
    -ie-box-shadow: 0 8px 8px -5px #999
    background: #fff
    transform: rotate(1deg)
    -moz-transform: rotate(1deg)
    -ie-transform: rotate(1deg)
    -webkit-transform: rotate(1deg)
  .ui-sortable-placeholder
    background: #eee
  .removed, a.remove
    margin-top: 6px
    font-size: 12px
    display: inline-block
    color: #3b3b3b
    &:hover
      color: #000

.breadc
  font-family: proxima-nova, sans-serif
  font-style: normal
  font-size: 11px
  line-height: 24px
  color: #8e8e8a
  a
    color: #8e8e8a
    max-width: 200px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    display: inline-block

.modal
  border-radius: 0
  -webkit-border-radius: 0
  -moz-border-radius: 0
  border-color: #d8d8d8

.modal
  .close
    font-family: proxima-nova, sans-serif
    height: 32px
    width: 33px
    margin-top: -6px
    margin-right: -12px
    overflow: hidden
    opacity: 0.9
    color: #000
    font-size: 31px


@media (max-width: 979px)
  .large-view.modal
    text-align: center

.alert
  margin-top: 15px

h4.red-title
  background: #ec4b4a
  color: #fff
  font-weight: 100
  font-size: 20px
  line-height: 37px
  padding: 0 12px
  font-family: "proxima-nova", sans-serif
  margin: 3px 0

a.remove-favorite
  border: none !important
  color: #666 !important
  font-family: Arial
  font-size: 20px !important
  text-decoration: none !important
  margin: 6px
  float: right
  &:hover
    color: #999 !important
    text-decoration: none

.yellow-keeper
  padding: 33px 0
  &.footer
    background: transparent
    padding: 0
    .fb-like
      margin-top: 6px

a.searchable
  display: inline-block
  line-height: 20px
  padding: 5px 0 !important
  margin: 2px 6px 0 6px
  height: 20px
  &.icon
    border: 1px solid #6a6a6a
    border-left: none
    margin: 1px 6px 0 0
    padding: 5px 5px 3px 5px !important

a.add_to_fav, a.remove_from_fav
  text-decoration: none
  font-size: 19px
  height: 18px
  line-height: 18px !important
  padding: 0 6px
  i
    color: #3b3b3b
    vertical-align: top
a.remove_from_fav
  i
    color: #ec4b4a

a.try-sq
  position: absolute
  bottom: 9px
  left: 0
  display: block
  width: 108px
  height: 43px
  &.logged-in
    bottom: 24px

.works_reordering
  .row
    .icon-resize-vertical
      font-size: 20px

.six-steps
  .img
    display: inline-block
    position: relative
    margin: 10px
    max-width: 46%
    &.full
      max-width: 100%
    img
      width: 100%
    .share
      position: absolute
      top: 0
      right: 0
      padding: 12px
      a
        text-shadow: 0 0 4px #fff
        opacity: 0
        font-size: 27px
        color: #3b3b3b
        padding: 0 12px
        -moz-transition: opacity 0.3s
        -o-transition: opacity 0.3s
        -ms-transition: opacity 0.3s
        -webkit-transition: opacity 0.3s
        transition: opacity 0.3s
        &:hover
          color: #000
    &:hover
      .share
        a
          opacity: 1
  h1, p.lead
    font-family: ff-meta-serif-web-pro, serif
  h1
    font-size: 28px
    font-weight: normal
  .large
    font-size: 18px
    line-height: 30px
    li
      line-height: 30px
  p.lead
    font-size: 21px
  .center
    text-align: center

@media (min-width: 1170px)
  .six-steps
    .img
      max-width: 100%

@media print
  a[href]:after
    content: ""

.store-widget-embed
  padding: 0
  textarea, p
    width: 90%
    margin: 0
    height: 22px

.refund-row
  a
    display: none
  &:hover
    a
      display: block

.admin-address
  color: #000
  font-weight: bold
  span
    color: #999
    font-weight: normal
  div
    border-bottom: 1px solid #ccc
    margin-bottom: 6px
    padding-bottom: 6px
  a
    font-weight: normal

.error-message
  font-family: "proxima-nova", sans-serif
  border: 1px solid #ec4b4a
  padding: 9px
  display: flex
  margin-bottom: 9px
  .em
    margin-right: 9px
    background: #ec4b4a
    color: #fff
    font-weight: bold
    border-radius: 50%
    width: 18px
    height: 18px
    display: flex
    justify-content: center
    align-items: center
    line-height: 18px
    font-size: 13px
  span
    flex: 1

.hidden-form
  margin: 0
  padding: 0

.new_commission
  color: #2556ae

a.new_commission
  text-decoration: underline
  &:hover
    color: #2556ae

#new_commission
  color: #2556ae

.grecaptcha-badge
  display: none
