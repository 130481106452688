.description-item
  width: 100%
  padding: 6px
  margin-top: 12px
  .text
    text-align: center
    margin-bottom: 12px
  hr
    border-top-color: #000
    width: 150px
    margin: 0 auto
.collection-items
  width: 940px - 12px
  -webkit-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.15)
  -ms-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.15)
  -moz-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.15)
  -o-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.15)
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.15)
  margin: 24px 0
  padding: 6px
  .description-item
    margin-top: 0
    float: left
    hr
      margin-bottom: 12px
.collection-item
  -webkit-box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15)
  -ms-box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15)
  -moz-box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15)
  -o-box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15)
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15)
  display: inline-block
  width: 292px
  height: 292px
  text-align: center
  margin: 6px
  position: relative
  float: left
  &.wall-i
    width: 595px
    height: 292px
    .wall-image
      position: absolute
      top: 9px
      left: 9px
      bottom: 9px
      right: 9px
  .sharing
    left: 50%
    -webkit-transform: translateX(-50%)
    -ms-transform: translateX(-50%)
    -moz-transform: translateX(-50%)
    -o-transform: translateX(-50%)
    transform: translateX(-50%)
    position: absolute
    bottom: 12px
    opacity: 0
    -webkit-transition: opacity 0.3s
    -ms-transition: opacity 0.3s
    -o-transition: opacity 0.3s
    -moz-transition: opacity 0.3s
    transition: opacity 0.3s
    a
      color: #000
      text-decoration: none
      &:hover
        color: #000
  a.buy-now
    opacity: 0
    display: block
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(255, 255, 255, 0.9)
    -webkit-transition: opacity 0.3s
    -ms-transition: opacity 0.3s
    -o-transition: opacity 0.3s
    -moz-transition: opacity 0.3s
    transition: opacity 0.3s
    color: #000
    text-decoration: none
    &:hover
      color: #000
    .inner
      position: relative
      top: 50%
      -webkit-transform: translateY(-50%)
      -ms-transform: translateY(-50%)
      -moz-transform: translateY(-50%)
      -o-transform: translateY(-50%)
      transform: translateY(-50%)
    .author, .description
      font-family: "ff-meta-serif-web-pro", serif
    .author
      font-size: 16px
      font-style: italic
    .description
      hr
        margin: 11px auto
        border-top: 1px solid #000
        width: 80px
    .button
      background: #b2285e
      color: #fff
      display: inline-block
      padding: 4px 14px
      font-size: 12px
      margin-top: 12px
  &:hover
    a.buy-now
      opacity: 1
    .sharing
      opacity: 1
  -webkit-box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15)
  -ms-box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15)
  -moz-box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15)
  -o-box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15)
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15)

  .preview-item
    margin: 0
    top: 50%
    left: 50%
    -ms-transform: translateX(-50%) translateY(-50%)
    -moz-transform: translateX(-50%) translateY(-50%)
    -o-transform: translateX(-50%) translateY(-50%)
    -webkit-transform: translateX(-50%) translateY(-50%)
    transform: translateX(-50%) translateY(-50%)
    posisiton: relative

    &.wide
      display: none
    &.narrow
      display: block

@media (min-width: 1200px)
  .collection-items
    width: 1170px - 12px
  .collection-item
    width: 369px
    height: 369px
    &.wall-i
      width: 750px
      height: 369px
    .preview-item
      &.wide
        display: block
      &.narrow
        display: none
