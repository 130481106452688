/*----------------------
 * Project Common Classes
 *----------------------
/*---- Floats ----

.flR
  float: right

.flL
  float: left

/*---- Horizontal Alignment ----

.alR
  text-align: right

.alC
  text-align: center

/*---- Vertical Alignment ----

.vlT
  vertical-align: top

.vlB
  vertical-align: bottom

/*---- Image Replacement Technique ----

.ir
  position: relative
  overflow: hidden
  span
    position: absolute
    top: 0
    text-indent: -1000em

//------------------------------------------
///* LAYOUT
//     Define the basic template: header, footer, etc. Elements that help to define the basic layout of the site
//  ------------------------------------------*/

a
  outline: none !important

body
  color: #3b3b3b
  font-family: "proxima-nova",sans-serif

/*----- Navbar ---

#main
  padding-bottom: 5px
  .inner-main
    padding: 0 0px 10px 0
    margin-right: 10px

/*----------------------
 *   PAGE COMMON STYLES
 *----------------------

/*----- Page Header ---

.page-header
  padding: 0
  margin: 0 0 30px
  border: 0
  h1
    margin: 0
    padding: 0
    border: 0
    font-weight: normal
    color: #3b3b3b
    font-size: 20px
    line-height: normal

/*------- BOXES ------------

.box1, .box6
  padding: 30px 23px 0 23px
  min-height: 500px
  margin-bottom: 30px
.box1
  background: #f5f5e4

.simple_form.edit_user
  padding-bottom: 12px

/*------ Box2 -----

.box2
  background: #fff
  border: 1px solid #bebebe
  margin-bottom: 10px
  h3
    font-size: 14px
    color: #3b3b3b
    height: 25px
    line-height: 25px
    padding: 0 8px
    margin: 0 0
    font-weight: normal
    border-bottom: 1px solid #bebebe

/*----- Buttons -----

/*----- btn-red ----

.btn-red
  padding: 0 24px
  font-size: 18px
  font-family: "ff-meta-serif-web-pro", serif
  font-style: italic
  padding: 0 24px
  height: 28px
  border-radius: 0
  -moz-border-radius: 0
  -webkit-border-radius: 0
  box-shadow: 2px 2px 2px #d0d0c2
  -moz-box-shadow: 2px 2px 2px #d0d0c2
  -webkit-box-shadow: 2px 2px 2px #d0d0c2
  background: #ec4b4a
  border: 0
  text-shadow: none
  color: #fff
  &:hover
    background: #ec4b4a
    color: #fff

/*----- btn-yellow ---

@mixin work-status
  padding: 0 15px
  height: 20px
  line-height: 20px
  font-size: 12px
  text-transform: uppercase
  text-align: center
  min-width: 105px
  border-radius: 0
  -moz-border-radius: 0
  -webkit-border-radius: 0
  box-shadow: none
  -moz-box-shadow: none
  -webkit-box-shadow: none
  border: 0
  text-shadow: none

.btn-yellow
  @include work-status
  background: #f0f0aa
  color: #666666
  &:hover
    background: #f0f0aa
    color: #3b3b3b
  &:disabled
    color: #fff
    background: #3b3b3b
    opacity: 1

/*---- Edit-btn ---

a.edit-btn
  display: inline-block
  padding: 3px 7px
  font-size: 12px
  color: #666
  margin-left: 10px
  background: #f0f0aa

/*------- LISTS --------

.list1
  background: url("./images/theme/border-bg1.png") repeat-x 0 0
  padding-top: 15px
  margin: 0 0 50px
  li
    list-style: none
    background: url("./images/theme/border-bg1.png") repeat-x 0 bottom
    padding-bottom: 9px
    margin-bottom: 15px
    .image
      width: 132px
      float: left
      margin: 0 20px 0 12px
      img
        display: block
        margin: 0 auto
        border: 1px solid #bebebe
        max-width: 99%
    .description
      float: left
      width: 550px
      h3
        font-size: 14px
        color: #3b3b3b
        font-weight: normal
        line-height: normal
        margin-bottom: 8px
        margin-top: 0
        a
          color: #3b3b3b
      p
        font-size: 12px
        line-height: 20px
        margin-bottom: 0
      h5
        font-size: 12px
        line-height: 20px
        margin-bottom: 0
        font-weight: normal
        line-height: normal
        margin-top: 0
    span.metadata
      float: right
      display: block
      font-size: 14px
      color: #3b3b3b
    .btn-yellow
      float: right

/*------ Form ---

.form ul
  margin: 0
  li
    list-style: none
    margin-bottom: 15px
    font-size: 16px
    label
      margin-bottom: 8px
      padding-left: 8px
      display: block
    input.textbox
      background: #fff
      border: 1px solid #bebebe
      height: 18px
      color: #3b3b3b
      font-size: 14px
      border-radius: 0
      -moz-border-radius: 0
      -webkit-border-radius: 0
      box-shadow: none
      -moz-box-shadow: none
      -webkit-box-shadow: none
      margin-bottom: 0
    textarea
      background: #fff
      border: 1px solid #bebebe
      padding: 10px
      color: #3b3b3b
      font-size: 14px
      border-radius: 0
      -moz-border-radius: 0
      -webkit-border-radius: 0
      box-shadow: none
      -moz-box-shadow: none
      -webkit-box-shadow: none
      margin-bottom: 0
      resize: none
    select
      background: #fff
      border: 1px solid #bebebe
      height: 18px
      color: #3b3b3b
      font-size: 14px
      border-radius: 0
      -moz-border-radius: 0
      -webkit-border-radius: 0
      box-shadow: none
      -moz-box-shadow: none
      -webkit-box-shadow: none
      height: 26px
      margin-bottom: 0

/*----- Accordion1 ---

.accordion1
  background: url("./images/theme/border-bg1.png") repeat-x 0 0
  margin-bottom: 100px
  .accordion-group
    padding: 0
    border: 0
    margin: 0
  .accordion-heading
    background: url("./images/theme/border-bg1.png") repeat-x 0 bottom
    a
      padding: 9px 5px
      color: black
      font-weight: bold
      font-size: 16px
      text-decoration: none
  .accordion-body
    background: url("./images/theme/border-bg1.png") repeat-x 0 bottom
    p
      color: #3b3b3b
      font-size: 14px
      line-height: 18px
      margin: 0
    .accordion-inner
      padding: 9px 0
      border: 0

/*------ Sccordion2 ---

.accordion2
  .accordion-group
    padding: 0
    border: 0
    margin: 0
  .accordion-heading
    font-size: 14px
    color: #3b3b3b
    height: 25px
    line-height: 25px
    padding: 0 8px
    margin: 0 0
    font-weight: normal
    border-bottom: 1px solid #bebebe
    a
      padding: 0
      font-size: 14px
      color: #3b3b3b
      text-decoration: none
      background: url("./images/theme/arrows.jpg") no-repeat right 0
      &.collapsed
        background-position: right bottom
  .accordion-body
    border-bottom: 1px solid #bebebe
    margin-bottom: -1px
    .accordion-inner
      border: 0
      padding: 15px 10px

/*------ Table-1 ---

table.table1
  width: 100%
  border: 0
  tr
    th
      text-align: center
      font-size: 12px
      line-height: 14px
      vertical-align: top
      font-weight: normal
      small
        display: block
        font-size: 10px
        padding: 3px 5px
    td
      width: 80px
      text-align: center
      font-size: 12px
      vertical-align: middle
      padding: 3px 5px
      &.arrow
        width: 15px
      &.equals
        width: 15px
        font-family: Arial, Helvetica, sans-serif
      input.textbox
        background: #fff
        border: 1px solid #bebebe
        height: 18px
        color: #3b3b3b
        font-size: 14px
        border-radius: 0
        -moz-border-radius: 0
        -webkit-border-radius: 0
        box-shadow: none
        -moz-box-shadow: none
        -webkit-box-shadow: none
        margin: 0
        width: 74px
        text-align: center

/*----------------------
 *   LOGIN PAGE  STYLES
 *----------------------
.facebook-btn
  display: inline-block
  background: url("./images/theme/facebook-btn.jpg") no-repeat 0 0
  width: 162px
  height: 22px


/*----------------------------------
 *   PROFILE EDIT PAGE   STYLES
 *----------------------------------

#profile-edit-form
  .image
    float: left
    width: 160px
    height: 160px
    border: 6px solid #ffffff
    background: #f5f5e4
    margin-right: 55px
    margin-bottom: 10px
    position: relative
    .change-picture
      position: absolute
      background: url("./images/theme/change-picture.png") no-repeat 0 0
      width: 91px
      height: 91px
      z-index: 200
      top: 30px
      left: 30px
  .span6 ul
    padding-top: 20px
  textarea
    width: 500px
    resize: none
    height: 90px
    &.about
      height: 160px
  .btn-red
    margin-left: 20px
    vertical-align: bottom

.image-size
  display: block
  font-size: 36px
  margin-bottom: 10px
  color: #000
  line-height: 1em

/*----------------------------------
 *   PROFILE PAGE  STYLES
 *----------------------------------

#my-profile
  padding-top: 30px
  .profile-image
    max-width: 160px
    max-height: 160px
    margin: 0 36px 12px 0
    float: left
  a.public-profile
    margin-left: 0
    margin-top: 12px
  .image
    cursor: default
    width: 158px
    height: 158px
    padding: 1px
    background: #fff
    border: 1px solid #bebebe
    margin-bottom: 27px
  .user-info
    padding-top: 75px
    p
      font-size: 14px
      color: #3b3b3b
      margin-bottom: 25px
      a
        color: #ec4b4a
        font-size: 16px
        text-decoration: underline
        font-style: italic
        &:hover
          text-decoration: none
    h2
      color: #2e2d2b
      font-size: 22px
      font-weight: normal
      margin-bottom: 10px
      margin: 0 0 10px
      line-height: normal
    span.location
      display: block
      color: #666
      font-size: 14px

/*----------------------------------
 *   MYWORKS PAGE  STYLES
 *----------------------------------

#my-works
  padding-top: 10px
#my-works .header
  text-align: center
.user-warnings
  margin-top: 8px
  padding-bottom: 16px
  margin-bottom: 8px
  border-bottom: 1px dashed #aaa
#my-works .header, .user-warnings
  min-height: 53px
  margin-bottom: 10px
  position: relative
  .btn-red
    position: absolute
    right: 0
    top: 15px
#my-works .header, .user-warnings, .pending-arts-warning
  p
    color: #3b3b3b
    font-size: 14px
    line-height: 18px
    a
      color: #ec4b4a
      text-decoration: underline
      font-style: italic
      font-size: 14px !important
.pending-arts-warning
  text-align: left
  li
    font-weight: bold
  padding-bottom: 16px
  margin-bottom: 8px
  border-bottom: 1px dashed #aaa

/*------ Work-upload-form ---

#work-upload-form
  h2
    color: #3b3b3b
    font-size: 20px
    font-weight: normal
    margin-bottom: 30px
    line-height: normal
    font-weight: bold
  position: relative
  .re-upload
    position: absolute
    top: 5px
    left: 257px
    z-index: 200
  h2 small
    display: block
    color: #3b3b3b
    font-size: 14px
    padding-top: 7px
  textarea
    width: 520px
  select
    float: left
    margin-right: 20px
    width: 160px
  p.checkbboxes
    font-size: 12px
    margin-bottom: 12px
    font-size: 12px
    label
      display: inline-block
      margin-right: 8px
      font-size: 12px
  .sell-details .span4
    width: 309px
    float: right
    margin-bottom: 60px
  .image-crop
    padding: 7px 10px
  .slide
    margin-bottom: 25px
    span
      display: block
      float: left
      font-size: 12px
      color: #3b3b3b
  p a
    text-decoration: underline
    color: #ec4b4a
  span
    &.drag-handle
      display: inline-block
      background: url("./images/theme/drag-handle.png") no-repeat 0 0
      height: 14px
      line-height: 14px
      padding-left: 20px
      font-size: 12px
      margin-left: 10px
    &.crop-size
      height: 14px
      line-height: 14px
      height: 14px
      line-height: 14px
      font-size: 12px
      margin-left: 55px
  .btn-red,.reupload_link
    float: right
    margin-right: 30px
  .reupload_link
    color: #ec4b4a
    font-size: 12px
    text-decoration: underline
    font-style: italic
    line-height: 25px

/*----------------------------------
 *   PROFILE PAGE  STYLES
 *----------------------------------

body.layout1
  .navbar
    background: #3b3b3b
  .wrapper
    width: 1135px
    margin: 0 auto
  #sidebar
    width: 183px
    float: left

.backward-compatible
  input[type=text], select
    height: 36px !important
    width: 100%
  textarea
    width: 100% !important
