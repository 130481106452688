.artist-works
  &.list1
    li .description h3 a
      font-size: 120%
  a.items-sold
    color: #ec4b4a
    i
      color: #ec4b4a
    &:hover, &:focus
      color: #ec4b4a
