.boutique
  font-family: "proxima-nova", sans-serif
  *
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box
  .banner
    img
      width: 100%
  .boutique-item
    margin: 20px 0
    height: 176px
    position: relative
    padding: 30px 420px 30px 17px
    h2
      font-size: 28px
      font-weight: 400
      margin: 0
      padding: 0
    p
      font-size: 22px
      line-height: 22px
      font-weight: 300
    .img
      position: absolute
      right: 0
      height: 176px
      width: 401px
      top: 0
    a
      font-size: 18px
      color: #000
      display: block
      position: absolute
      bottom: 14px
      left: 14px
      padding: 0 12px
      line-height: 37px
      background: #fff
      i
        -moz-transition: all 0.5s
        -o-transition: all 0.5s
        -webkit-transition: all 0.5s
        -ms-transition: all 0.5s
        transition: all 0.5s
    a.active
      i
        display: inline-block
        -webkit-transform: rotate(90deg)
        -moz-transform: rotate(90deg)
        -o-transform: rotate(90deg)
        transform: rotate(90deg)
        writing-mode: tb-rl
    .boutique-widget
      display: none


@media (min-width: 1200px)
  .boutique
    .boutique-item
      padding: 40px 520px 40px 31px
      height: 220px
      .img
        height: 220px
        width: 517px
      a
        bottom: 40px
        left: 31px
