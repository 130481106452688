@use "arts/assets/variables" as variables;

$bottom-space: 22px;

.modal {
  @media (max-width: 582px) {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    margin: 0 !important;
  }
}

.modal-scrollable {
  justify-content: center;
  align-items: center;
}

.sign_in_up {
  @include variables.modal-window;

  .fav-holder {
    height: 70px;
    @media (max-width: 452px) {
      height: 56px;
    }
  }

  .fav_title {
    position: absolute;
    background: variables.$light-brown;
    color: variables.$light-brown-text;
    css {
      fill: variables.$light-brown-text;;
    }
    width: 100%;
    top: 0;
    left: 0;
    font-size: 20px;
    line-height: 22px;
    padding: 36px;
    display: flex;

    img {
      width: 40px;
    }

    span {
      margin-left: 12px;
    }

    @media (max-width: 490px) {
      font-size: 16px;
      line-height: 18px;
    }

    @media (max-width: 452px) {
      img {
        width: 30px;
      }
      span {
        margin-left: 9px;
      }
      font-size: 14px;
      line-height: 16px;
      padding: 36px 21px;
    }

    @media (max-width: 452px) {
      padding: 36px 12px;
    }
  }

  .fav_details {
    display: none;
  }

  .modal-header {
    border-bottom: none;
    margin-bottom: 6px;
  }
  .modal-body {
    padding-bottom: 7px;
  }
  .alert {
    margin-top: 0;
  }
}

.sign-in-up-screen {
  margin: 0 auto 30px;
  flex-direction: column;
  @include variables.width-keeper;
}

.sign-in-up-screen, .sign-in-up-screen-m {
  @include variables.page-title-h2;
  .rows {
    display: flex;
    @media (max-width: variables.$w-1-mobile) {
      display: block;
    }
  }

  .l {
    width: 45%;
    padding: 0 42px 0 0;
    @media (max-width: variables.$w-1-mobile) {
      width: 100%;
      padding: 0;
    }
  }

  .l1 {
    width: 100%;
  }

  .l, .l1 {
    form {
      padding-top: 15px;
      display: flex;
      flex-direction: column;
      .rows {
        div {
          flex: 1;
        }
        div:first-child {
          margin-right: 12px;
          @media (max-width: variables.$w-1-mobile) {
            margin-right: 0;
          }
        }
      }
      label {
        margin-bottom: 0;
      }
      input[type=text], input[type=password], input[type=email] {
        padding: 11px;
        width: 100%;
        height: auto;
        flex: 1;
        border: 1px solid #000;
        border-radius: 0;
        margin-bottom: 0;
        font-size: 16px;
        &:focus {
          box-shadow: none;
          font-size: 16px;
        }
      }
      .controls {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
      }
      button {
        @include variables.action-button;
        height: 44px;
        margin: 22px 0;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      margin-bottom: $bottom-space;
      padding: 15px 0;
      border-bottom: 1px solid #ccc;
    }
    p {
      text-align: center;
      padding-bottom: $bottom-space;
    }
    a {
      color: #000;
      text-decoration: underline;
    }
    p.other-links {
      color: #999;
      a {
        color: #999;
      }
    }
  }

  .r {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    flex: 1;
    height: 0;
    padding-top: 44%;
    margin-top: variables.$sub-section-height;
    @media (max-width: variables.$w-1-mobile) {
      min-height: 30vh;
    }
  }
}

.user_password_confirmation, .user_sign_up_consent {
  margin-right: 0 !important;
}


.control-group.boolean.user_sign_up_consent {
  display: flex;
  flex-direction: row-reverse;
  label.boolean {
    width: 100%;
  }
}
