.orders
  .title-row
    margin-bottom: 3px
  .order
    border: 1px #cfcfcf solid
    margin: 12px 0
    padding: 6px 0
    &.first
      margin-top: -1px
  .boxed, .order
    .row
      margin-left: -22px
  .total-pad
    padding: 3px 12px 3px 0
  .pad
    padding: 0 12px
  .order-item
    padding: 6px 0
    border-bottom: 1px dashed #8e8e8e
    &.last
      border-bottom: none
  .boxed
    padding: 3px 0
    &.item-details
      margin-top: -2px
  a
    color: #ec4b4a
    &.back
      color: #fff
      font-size: 14px
      font-family: ff-meta-serif-web-pro, serif
      border-bottom: 1px dashed #fff
      font-style: italic
  a.shipped
    color: #000
    text-decoration: underline
  .middle-delimiter
    border-top: 1px solid #cfcfcf
    height: 3px
  strong, address, .payment-method
    color: #8e8e8a
  .order-status
    font-size: 18px
    font-weight: bold
  .cost
    font-size: 16px
    color: #000
  .details
    font-size: 12px
    color: #8e8e8a
    margin-bottom: 6px
    font-family: proxima-nova, sans-serif
  .user-name
    font-size: 12px
  .item
    .aligned
      padding-top: 12px
  .items-shipped
    .boxed
      color: #fff
      background: #3b3b3b
      border: 1px solid #3b3b3b
      .span12
        padding: 0 12px

@media (min-width: 1200px)
  .orders
    .boxed, .order
      .row
        margin-left: -32px
