/*------ Breadcrumbs -----

#breadcrumbs
  text-align: right
  margin-bottom: 7px
  a, span
    font-size: 11px
    color: #bbbbbb

/*---- Promo-bar ---

#promobar
  background: url("./images/theme/border-bg1.png") repeat-x 0 bottom
  padding-bottom: 10px
  margin-bottom: 17px
  .container
    position: relative
  img
    display: block
    margin: 0 auto
  #breadcrumbs
    position: absolute
    right: 0
    top: 10px


/*---- Search-Filter ---



.navbar-delimiter, .middle-small-delimiter
  background: transparent url(./images/theme/border-bg1.png) repeat-x 0 bottom
  height: 1px
  margin-bottom: 3px
.middle-small-delimiter
  margin-bottom: 5px
.navbar.filter-navbar
  margin: 0 auto 4px auto
  font-size: 12px
  color: #fff
  .navbar-inner
    min-height: 1px
    padding: 3px 0 3px 0
    background-image: none
    background-color: #3b3b3b
    border: none
    border-radius: 0
    -webkit-border-radius: 0
    -moz-border-radius: 0
    .container
      border-top: 1px solid #fff
      border-bottom: 1px solid #fff
  .nav > li > a, .nav > li.title, .nav > li.tag
    padding: 3px 10px
  .nav > li > a, .nav > li.title
    text-transform: uppercase
  a, a:hover, a:active
    color: #fff
  a.remove_filter
    font-size: 20px
    color: #fff
    &:hover
      color: #fff
  .nav > li > a
    color: #fff
    text-shadow: none
    -webkit-text-shadow: none
    -moz-text-shadow: none
    &:hover, &:active
      color: #fff
  li > .dropdown-menu:after
    border-bottom: 6px solid #3b3b3b
  .dropdown-menu
    background-color: #3b3b3b
    border-radius: 0
    -webkit-border-radius: 0
    -moz-border-radius: 0
    li > a:hover, li > a:focus
      background-color: #e6e6e6
      color: #3b3b3b
      background-image: none

.navbar-container
  position: fixed
  width: 100%
  z-index: 2
  background: #fff
  .navbar-delimiter
    margin: 0 auto
.navbar.filter-navbar, .navbar-delimiter
  width: 940px
@media (min-width: 1200px)
  .navbar.filter-navbar, .navbar-delimiter
    width: 1170px

#search-filter
  font-family: "ff-meta-serif-web-pro", serif
  .tags
    .tag
      display: inline-block
      margin: 0 5px 5px 0
      background: #f5f5e4
      height: 18px
      line-height: 18px
      position: relative
      border: 1px solid #d8d8d8
      border-radius: 2px
      -moz-border-radius: 2px
      -webkit-border-radius: 2px
      font-size: 12px
      color: #8e8e8a
      padding: 0 7px
      text-decoration: none
      font-family: "proxima-nova",sans-serif
      span
        margin-left: 8px
      &:hover span
        color: #3b3b3b
      a
        color: #8e8e8a

/*----- Tags -------

/*---- Infobar ---

.infobar
  text-align: right
  margin-bottom: 10px
  p
    color: #3b3b3b
    font-size: 10px
    text-transform: uppercase
    margin: 0

/*---- Artists Page Styles ---

.artist
  background: #fff
  border: 1px solid #d8d8d8
  padding: 5px 10px
  margin-bottom: 7px
  .artist-info
    padding-top: 5px
    width: 245px
    margin-right: 10px
    float: left
    img
      float: left
      margin-right: 11px
    h3
      margin-bottom: 3px
      color: #2e2d2b
      font-size: 16px
      font-weight: normal
      line-height: normal
      a
        color: #2e2d2b
        text-decoration: underline
        &:hover
          text-decoration: none
    span
      display: block
      font-size: 12px
      color: #2e2d2b
  .artist-works
    float: left
    margin: 0
    li
      list-style: none
      float: left
      margin: 0 10px 0 0


/*---- Popup2 ---

.popup2
  position: absolute
  z-index: 1051
  right: 0
  top: 26px
  width: 370px
  a.close-btn
    display: block
    position: absolute
    width: 6px
    height: 7px
    background: url("./images/theme/close-btn2.gif") no-repeat 0 0
    z-index: 1100
    right: 8px
    top: 8px

/*---- SHOPPING CART ----


a
  vertical-align: top
  &:hover
    text-decoration: none
  &.zoom
    display: inline-block
    background: url("./images/theme/zoom-icon.png") no-repeat 0 0
    width: 24px
    height: 20px
  &.scale
    display: inline-block
    background: url("./images/theme/scale-icon.png") no-repeat 0 0
    padding-left: 35px
    line-height: 10px
    height: 20px
    color: #3b3b3b
    font-size: 10px

.promo
  color: #ec4b4a
  padding-left: 12px
  font-size: 14px
  line-height: 16px
  font-weight: bold
  strong
    font-size: 18px
@media (min-width: 1200px)
  .promo
    padding-left: 0

.not-for-sale
  color: #ec4b4a
  font-size: 12px

@media (min-width: 1200px)
  .wall .description
    float: right
    .inner
      padding-left: 35px
    p
      width: 80%
  .wall .description
    p
      max-height: 242px
  #search-filter ul li.artists ul.child li
    margin-left: 34px
