@use "arts/assets/variables" as variables;

$brand-height-mobile:   20px;
$brand-height:          49px;
$navigation-padding:    6px;
$navigation-padding-mobile: 14px;

.about-b {
  background: variables.$light-brown;
  color: variables.$light-brown-text;

  a, a:active, a:hover {
    color: variables.$light-brown-text;
  }

  > div {
    @include variables.width-keeper;
    & {
      margin: 0 auto;
      height: 37px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0 variables.$mobile-padding;
      letter-spacing: 0.44px;

      @media (max-width: variables.$w-1-mobile) {
        height: auto;
        padding: 12px variables.$mobile-padding;
        font-size: 12px;
        display: block;
      }

      @media (min-width: variables.$m-w-4) {
        height: 46px;
      }
    }
  }
}

#user-navbar {
  position: sticky;
  top: 0px;
  z-index: 702; // home page arrows have 701
  background: #fff;

  input#menuc {
    display: none;
  }

  .hamb {
    z-index: 3;
    svg {
      width: 20px;
      height: 20px;
      pointer-events: none;
    }

    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    padding: 14px variables.$mobile-padding;

    @media (min-width: variables.$w-1-mobile) {
      display: none;
    }
  }
}

.inner-container3 {
  padding: 0 10px;
  width: 100%;
}

header, .inner-container20 {
  margin: 0 auto;
  @include variables.width-keeper;
}

header {
  display: flex !important;
  position: relative;
  height: variables.$header-banner-height-mobile;
  border-bottom: 1px solid variables.$checkout-line;

  .rr {
    .signin-link, .signed-in, .signup-link, .select_locale, .blog-link {
      display: none;
    }
  }

  @media (min-width: variables.$w-1-mobile) {
    height: variables.$header-banner-height;
    border-bottom: none;
    .rr {
      .signin-link, .signed-in, .signup-link, .select_locale {
        display: block;
      }
      .blog-link {
        display: inline-block;
      }
    }
  }

  @media (min-width: variables.$m-w-4) {
    height: variables.$header-banner-height-w4;
  }

  .ll, .rr {
    padding-top: $navigation-padding;
    display: flex;
    text-transform: uppercase;
    color: variables.$darkgrey;
    font-size: 11px;
    font-family: variables.$proxima-font-family;
  }

  .rr {
    padding-top: $navigation-padding-mobile;

    @media (min-width: variables.$w-1-mobile) {
      position: relative;
    }

    #permanent-search {
      position: absolute;
      bottom: 16px;
      right: 0;
      width: 239px;
      @media (max-width: variables.$w-1-mobile) {
        display: none;
      }
      @media (min-width: variables.$m-w-4) {
        width: 339px;
      }
    }

    @media (min-width: variables.$w-1-mobile) {
      padding-top: $navigation-padding;
    }
  }

  .ll {
    display: none;
    flex-direction: column;

    @media (min-width: variables.$w-1-mobile) {
      display: flex;
    }
    a, a:hover, a:active {
      font-size: 11px;
      font-family: variables.$proxima-font-family;
      color: variables.$darkgrey;
      text-decoration: underline;
      letter-spacing: 0.44px;
    }
  }

  .rr {
    flex-direction: row;

    a.pending-cart-items {
      display: block;

      svg {
        width: 10px;
        height: 18px;
        margin-right: 3px;
        pointer-events: none;
      }

      div {
        display: flex;
      }
    }


    a, a.dropdown-toggle, a.pending-cart-items {
      font-size: 11px;
      font-family: variables.$proxima-font-family;
      color: variables.$darkgrey;
      text-decoration: none;
      letter-spacing: 0.44px;
      &:hover, &:focus {
        text-decoration: none;
      }
    }

    .dropdown, .signin-link, .signup-link, .blog-link {
      margin-right: 12px
    }

    .blog-link {
      color: #fff;
      background: #836305;
      padding-left: 4px;
      padding-right: 4px;
    }

    .dropdown-menu {
      background: #fff;
      border-color: #6a6a6a;
      border-radius: 0;
      li {
        height: 21px;
        a {
          padding: 0 6px;
          color: variables.$darkgrey;
          text-decoration: none;
          font-family: variables.$proxima-font-family;
          letter-spacing: 0.44px;
          &:hover, &:focus {
            color: variables.$darkgrey;
            background-color: #fff;
            background-image: none;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .logo {
    flex: 1;
  }

  .brand {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    background: url("./images/logo-wide.svg") no-repeat 0 0;
    background-size: contain;
    height: $brand-height-mobile;
    background-position-y: 0;
    top: 18px;
    width: 200px;
    margin-left: -14px;
    text-decoration: none;
    @media (min-width: variables.$w-1-mobile) {
      top: 20px;
      margin-left: 0;
      height: $brand-height;
      background-position-y: $brand-height - 25px - 13px;
      width: 325px;
    }
  }
}

@media (max-width: variables.$w-1-mobile - 1) {
  .search-visible header {
    height: variables.$header-banner-height-mobile + variables.$search-height;
  }
}



nav {
  transition: height 300ms;
  display: none !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border-top: none;
  border-bottom: none;
  height: 100vh;
  display: flex;

  .mobile-hidden {
    display: none !important;
  }

  .mobile-visible {
    display: flex !important;
    a {
      font-size: 12px;
    }
  }

  @media (min-width: variables.$w-1-mobile) {
    height: variables.$navigation-height + 2px;
    display: block !important;
    position: relative;
    border-top: 1px solid variables.$checkout-line;
    border-bottom: 1px solid variables.$checkout-line;

    .mobile-hidden {
      display: flex !important;
    }

    .mobile-visible {
      display: none !important;
    }
  }

  @media (min-width: variables.$m-w-4) {
    height: variables.$navigation-height-w4 + 2px;
  }
}

.ui.pagination.menu {
  display: inline-flex !important;
  position: static;
  height: auto;
}

.nav-keeper {
  margin: 0 auto;
  transition: height 300ms;
  justify-content: center;
  display: flex;
  height: variables.$navigation-height;

  .color-picker {
    div {
      display: flex;
    }
    a {
      display: block;
      width: 12px;
      height: 12px;
      border: 1px solid #fff;
      text-decoration: none;
      &:hover, &:active {
        text-decoration: none;
      }
    }
  }

  @media (max-width: variables.$w-1-mobile - 1) {
    margin-top: variables.$header-banner-height-mobile;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, .5);
  }

  @media (min-width: variables.$w-1-mobile) {
    @include variables.width-keeper;
  }

  @media (min-width: variables.$m-w-4) {
    height: variables.$navigation-height-w4;
  }


  ul.navigation {
    width: 100%;
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-direction: column;
    background: #fff;
    padding: 8px variables.$mobile-padding;

    @media (max-width: (variables.$w-1-mobile - 1)) {
      position: sticky;
      width: 90%;
      height: 100%;
      left: 0;
      margin: 0 auto 0 0;
      top: variables.$header-banner-height-mobile;
      padding-bottom: 2 * variables.$header-banner-height-mobile;
      overflow-y: auto;
      display: block;
    }

    @media (min-width: variables.$w-1-mobile) {
      padding: 0;
      justify-content: space-between;
      flex-direction: row;
    }

    li {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 6px 0;

      .new {
        position: absolute;
        top: -4px;
        right: -9px;
        height: 7px;
        @media (max-width: variables.$w-1-mobile) {
          position: static;
          margin: 2px 0 0 7px;
          height: unset;
        }
      }

      &.delimiter {
        border-bottom: 1px solid variables.$checkout-line;
        height: 0;
        padding: 12px 0 0 0;
        margin-bottom: 12px;
      }

      @media (min-width: variables.$w-1-mobile) {
        height: variables.$navigation-height;
        flex-direction: column;
        align-items: center;
      }

      @media (min-width: variables.$m-w-4) {
        height: variables.$navigation-height-w4;
      }

      &:last-child {
        margin-right: 0;
      }

      & > a {
        flex: 1;
        display: flex;
        align-items: center;
        height: 31px;
        position: relative;

        @include variables.navigation-font-sizes;

        & {
          color: #000;
          border-top: 2px solid transparent;
          border-bottom: 2px solid transparent;
          text-decoration: none;
          @media (min-width: variables.$w-1-mobile) {
            &.active, &:focus, &:hover, &:active {
              border-bottom: 2px solid #000;
              text-decoration: none;
            }
          }

          &.gifts {
            color: #836305;
          }

          span.o {
            color: variables.$red;
          }

          &.mobile-expand {
            display: none;
            @media (max-width: variables.$w-1-mobile - 1) {
              display: flex;
              @include variables.expandable-arrow;
            }
          }
        }
      }

      &.mobile-visible {
        a {
          font-size: 12px;
        }
      }

      &.line-links {
        a {
          flex-grow: 0;
          white-space: nowrap;
          margin-right: 12px;
          text-transform: uppercase;
        }
      }

      .with-scroll {
        overflow-y: auto;
        max-height: 600px;
      }

      section {
        display: none;
        position: absolute;
        z-index: 2;
        top: variables.$navigation-height - 1px + 2px;
        left: 0;
        width: 100%;
        background: #fff;
        box-shadow: variables.$nav-shadow;
        min-height: 450px;
        padding: 33px 0;

        .group {
          @media (min-width: variables.$w-1-mobile) {
            margin-bottom: 12px;
          }

          @media (min-width: variables.$m-w-2) {
            margin-bottom: 14px;
          }

          @media (min-width: variables.$m-w-4) {
            margin-bottom: 16px;
          }
        }

        @media (min-width: variables.$m-w-4) {
          top: variables.$navigation-height-w4 - 1px;
        }

        &.mobile-section {
          @media (max-width: variables.$w-1-mobile - 1) {
            padding: 0;
            position: static;
            top: auto;
            left: auto;
            box-shadow: none;
            min-height: auto;

            .inner-container20 {
              margin: 6px 0;
              padding: 0;
            }

            .rows.art-4columns {
              border-top: none;
            }

            .rows {
              a {
                line-height: 300% !important;
              }
            }

            h5.mobile-expand {
              @include variables.expandable-arrow;

            }

            h5.mobile-expand.expanded ~ a {
              display: flex;
            }

            h5.mobile-expand ~ a {
              display: none;
            }

            h5.mobile-expand.expanded ~ .color-picker {
              display: block;
            }

            h5.mobile-expand ~ .color-picker {
              display: none;
            }
          }
        }

        .rows {
          padding: 0;
          margin: 0;
          flex: 1;
          display: grid;
          @include variables.navigational-links;

          a.seeAll {
            text-decoration: underline;
            font-weight: bold;
          }

          img {
            width: 100%;
          }

          .pick-name {
            margin-top: 12px;
            @include variables.large-text;
          }

          .by-name {
            @include variables.small-text;
          }

          &.art-4columns {
            grid-template-columns: variables.$grid-f1w variables.$grid-f1 variables.$grid-f1 auto;
            grid-column-gap: variables.$grid-fg-1;

            @media (max-width: variables.$w-1-mobile - 1) {
              display: flex;
              flex-direction: column;
              border-top: 1px solid #ccc;
            }

            @media (min-width: variables.$m-w-2) {
              grid-template-columns: variables.$grid-f2w variables.$grid-f2 variables.$grid-f2 auto;
              grid-column-gap: variables.$grid-fg-2;
            }

            @media (min-width: variables.$m-w-3) {
              grid-template-columns: variables.$grid-f3w variables.$grid-f3 variables.$grid-f3 auto;
              grid-column-gap: variables.$grid-fg-3;
            }

            @media (min-width: variables.$m-w-4) {
              grid-template-columns: variables.$grid-f4w variables.$grid-f4 variables.$grid-f4 auto;
              grid-column-gap: variables.$grid-fg-4;
            }
          }

          &.collection {
            grid-template-columns: variables.$grid-fg-1 + variables.$grid-f1 * 2 auto;
            grid-column-gap: variables.$grid-fg-1;

            @media (max-width: variables.$w-1-mobile - 1) {
              display: flex;
              flex-direction: column;
              border-top: 1px solid #ccc;
            }

            @media (min-width: variables.$m-w-2) {
              grid-template-columns: variables.$grid-fg-2 + variables.$grid-f2 * 2 auto;
              grid-column-gap: variables.$grid-fg-2;
            }

            @media (min-width: variables.$m-w-3) {
              grid-template-columns: variables.$grid-fg-3 + variables.$grid-f3 * 2 auto;
              grid-column-gap: variables.$grid-fg-3;
            }

            @media (min-width: variables.$m-w-4) {
              grid-template-columns: variables.$grid-fg-4 + variables.$grid-f4 * 2 auto;
              grid-column-gap: variables.$grid-fg-4;
            }

            img {
              &.gift_card {
                max-width: 320px;
              }
            }
          }

        }
      }

      @media (min-width: variables.$w-1-mobile) {
        &:hover {
          section {
            display: flex;
          }
        }
      }

      @media (max-width: variables.$w-1-mobile - 1) {
        .mobile-expand.expanded ~ section.mobile-section {
          display: block;
        }

        .featured {
          padding: 24px 0;
          margin: 24px 0;
          border-top: 1px solid variables.$checkout-line;
          border-bottom: 1px solid variables.$checkout-line;
        }
      }
    }
  }
}

input#menuc:checked ~ nav {
  display: block !important;
}

input#menuc:checked ~ .hamb svg.menu {
  display: none;
}

.hamb svg.close {
  display: none;
}

input#menuc:checked ~ .hamb svg.close {
  display: block;
}

#mobile-search-item {
  display: none;
  @media (max-width: variables.$w-1-mobile - 1) {
    display: flex;
    > a {
      padding-right: 12px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.promo-banner {
  text-transform: uppercase;
  color: variables.$darkgrey;
  font-size: 11px;
  font-family: variables.$proxima-font-family;
  line-height: 10px;
  color: white;
  background: #ec4b4a;
  text-align: center;
  text-decoration: none;
  padding: 5px;
  cursor: pointer;
  h5 {
    margin: 0;
    letter-spacing: 1px;
    font-size: 13px;
  }
  span {
    font-weight: 100;
    font-size: 11px;
  }
}


.scroll-to-top {
  position: fixed;
  right: 12px;
  bottom: 12px;
  width: 60px;
  height: 60px;
  background: url("./images/top.png") 50% 50% no-repeat;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  transition: opacity 0.3s;
  z-index: 9999;
  padding: 0;
  margin: 0;
  outline: none;
  &.shown {
    opacity: 0.4
  }
  &:hover {
    opacity: 0.8
  }
  @media (max-width: variables.$w-1-mobile - 1) {
    display: none;
  }
}

.eu-c {
  height: 167px;

  & > div {
    position: fixed;
    height: 167px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 99;
    & > div {
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 167px;
      width: 740px;
      margin: 0 auto;
      @media (max-width: variables.$w-1-mobile - 1) {
        width: 100%;
      }
    }
  }
  p {
    padding: 0;
    margin-bottom: 12px;
    color: #3b3b3b;
    font-family: "proxima-nova", sans-serif;
    font-size: 14px;
    text-align: center;
  }
  a.close, a.privacy {
    text-decoration: underline;
    color: #3b3b3b;
    &:active, &:hover {
      color: #3b3b3b;
    }
  }
  a.continue {
    color: #fff;
    background-color: #000;
    width: 176px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
  a.close {
    position: absolute;
    right: 8px;
    top: 15px;
    font-size: 31px;
    text-decoration: none;
    opacity: 1;
  }
}

.select_locale {
  text-transform: uppercase;
}

.footer {
  min-height: 1290px;
  @media (min-width: variables.$w-1-mobile) {
    min-height: 340px;
  }
  @media (min-width: variables.$m-w-2) {
    min-height: 383px;
  }
  @media (min-width: variables.$m-w-4) {
    min-height: 429px;
  }
}
