@use "arts/assets/variables" as variables;

.profile_v2 {
  @include variables.width-keeper;
  @include variables.page-title;
  @include variables.summary;

  & {
    margin: 0 auto;
  }

  ul {
    max-width: 450px;
  }

  a, a:active, a:hover {
    color: variables.$black;
    text-decoration: underline;
  }

  .action {
    @include variables.action-button;
  }

  .form_v2 {
    @include variables.generic-form;
    abbr {
      display: none;
    }

    .field.withRadio {
      flex-direction: row;
      display: flex;

      label {
        font-weight: normal;
        display: flex;
        input {
          margin: 0;
        }
        label {
          margin: 0 12px 0 6px;
        }
      }
    }
  }
}
