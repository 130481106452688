@use "arts/assets/variables" as variables;

.user-publish-block {
  width: 180px;
  float: right;
  clear: right;
  span {
    color: #000;
    display: block;

    &.status-published {
      color: green;
    }

    &.status-rejected {
      color: #000;

      .learn-more {
        color: #000;
        text-decoration: underline;
      }
    }
  }

  .button {
    @include variables.white-button;
    border: 1px solid #000 !important;
  }

}


.partner-approval, .user-publish-block {
  .action-button {
    @include variables.action-button;
  }
}

.partner-approval h3 {
  margin-top: 10px !important;
  font-size: 16px !important;
}
