.filled
  background: #f5f5e4
  border: 1px #f5f5e4 solid

.boxed
  background: #f5f5e4
  border: 1px #CFCFCF solid

@media (min-width: 1200px)
  .modal-body
    .span6
      width: 460px

.strip
  background-color: #d8d8d8
  padding: 0px 10px
  font-family: "proxima-nova", sans-serif
  font-size: 12px


.cloudzoom-zoom
  z-index: 1051

.upload_work_form
  .page-header
    margin-bottom: 10px
    p
      max-width: 900px
      margin-top: 20px

#file_upload
  max-width: 900px
  input[type='file']
    display: none

#save_btn
  margin: 15px

#work_upload_progress, .upload_progress
  margin-top: 70px
  max-width: 900px
  border: 2px solid #bebebe
  height: 32px
  .bar
    height: 100%
    background-color: #bebebe
    float: left
    width: 0

.watermark
  .upload_progress
    margin-top: 0

#upload_status
  max-width: 900px
  text-align: center
  margin-top: 15px
  font-size: 15px
  color: #8e8e8a

.being_processed_message,.upload_your_first_work_now
  text-align: center
  font-size: 18px
  margin: 100px auto
  font-style: italic

.upload_your_first_work_now
  font-style: normal

.shopping-cart, .collection-item
  .preview-item
    display: block
    &.hidden-image
      display: none
    margin: 11px
    position: relative
    .frame-image, .mat, .image-small, .paper
      position: absolute
      top: 0
      left: 0
    .frame-image
      z-index: 1
      -webkit-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.45)
      -o-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.45)
      -moz-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.45)
      box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.45)
    .mat, .paper
      z-index: 2
      background: #fff
    .mat
      -webkit-box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.3)
      -o-box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.3)
      -moz-box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.3)
      box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.3)
    .paper
      -webkit-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.45)
      -o-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.45)
      -moz-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.45)
      box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.45)
      border: 1px solid #eee
    .image-small
      z-index: 3
  .product-item
    padding: 12px
    text-align: center


.additional_options
  margin: 0px -23px 0px -23px
  background-color: white
  border: 1px solid #d8d8d8
  .sell-details
    h4
      margin: -1px
      border: 1px solid #a09083
      background-color: #a09083
      color: #faf7fc
      text-transform: uppercase
      font-weight: normal
      font-family: proxima-nova, sans-serif
      font-size: 12px
      height: 30px
      line-height: 30px
      padding-left: 40px
  .additional_options_body
    margin: 20px
  .step_seporator
    background: url("./images/theme/border-bg1.png") repeat-x 0 0
    padding-top: 10px
    margin-top: 15px

.save_btn_row
  .btn
    margin-bottom: 20px

.btn-red
  color: white !important
  line-height: 30px
  text-decoration: none !important

.work-error-box
  text-align: center
  margin: 12px auto
  width: 440px
  padding: 18px
  border: 1px solid #ec4b4a
  background: #fff
  h5
    font-size: 18px
    color: #ec4b4a
    font-weight: normal
  p
    color: #8e8e8a
    font-size: 12px

#work-upload-form .work-error-box .reupload_link
  float: none
  margin-right: 0

.cloudzoom-zoom-inside, .cloudzoom-blank
  z-index: 1051

img.hidden-image
  display: none

.works_reordering
  padding: 16px 0
  .work
    max-height: 200px
    overflow: hidden
    margin-top: 16px
  .end
    height: 0
    margin-bottom: 16px
    clear: both
