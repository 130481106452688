@use "arts/assets/variables" as variables;

@mixin cms-page-sizes {
  font-size: 20px;
  line-height: 32px;
  @media (max-width: variables.$w-1-mobile - 1) {
    font-size: 16px;
    line-height: 22px;
  }
}

.cms-breadcrumbs {
  @include variables.width-keeper;
  & {
    list-style: none;
    display: flex;
    margin: 0 auto;
    padding: 9px 0;
    border-bottom: 1px dashed #8e8e8a;
    font-size: 14px;
    font-family: variables.$proxima-font-family;
    color: #999;
    li {
      margin-right: 4px;
      &:last-child {
        margin-right: 0;
      }
    }
    a {
      color: #999;
      &:hover, &:active, &:focus {
        color: #999;
        text-decoration: underline
      }
    }
  }
}

.cms_pages {
  @include variables.width-keeper;
  @include variables.page-title;
  & {
    margin: 0 auto;

    ul {
      @include variables.popularArtGrid;
      @include variables.grid2InRow;
    }

    a.action {
      @include variables.action-button;
    }

    a.img {
      display: block;
    }
  }
}

.popular-searches {
  text-align: justify;
  a {
    display: inline-block;
    background-color: #d9d9d9;
    margin-bottom: 16px;
    padding: 3px 12px;
    text-decoration: none !important;
    border: 1px solid #d9d9d9;
    transition: border-color .3s ease;

    &:hover, &:active {
      border-color: #000;
    }
  }
}

.cms_page {
  @include variables.width-keeper;
  @include variables.page-title;
  & {
    margin: 0 auto;
    max-width: 860px;

    .cms-delimiter {
      height: 0;
      width: 120px;
      border-top: 1px solid #000;
      margin: 24px auto;
      overflow: hidden;
    }

    h1 {
      justify-content: center;
      text-align: center
    }

    h3, h4, h5 {
      @include cms-page-sizes;
    }

    h2 {
      color: #000;
      font-family: variables.$ff-crimson-font-family;
      font-size: 24px;
      font-weight: 400;
      letter-spacing: .48px;
      @media (max-width: variables.$w-1-mobile - 1) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    img {
      width: 100%;
      padding-bottom: 12px;
    }

    a, a:active, a:hover {
      color: #000;
      text-decoration: underline;
    }

    li {
      @include cms-page-sizes;
    }

    p {
      white-space: pre-line;
      padding: 0 0 10px 0;
      @include cms-page-sizes();
    }

    p.regular {
      white-space: inherit;
    }

    p {
      &.cms-p-right {
        text-align: right;
      }
      &.cms-p-center {
        text-align: center;
      }
      &.cms-p-justify {
        text-align: justify;
      }
    }
  }
}
