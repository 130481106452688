@import "arts/assets/variables"

.hp-popup.canada
  width: 480px
  margin-left: -240px
  background: #ce0616
  padding: 9px
  .red-border
    border: 1px solid #fff
    background: #ce0616
    padding: 9px
    .red-container
      background: #fff
      text-align: center
      height: 200px
      padding: 48px
      color: #ce0616
      position: relative
      button.close
        position: absolute
        top: 0
        right: 10px
        background: transparent
        color: #ce0616
        text-indent: 0
      h4
        font-family: proxima-nova, sans-serif
        letter-spacing: 2px
        font-size: 26px
        line-height: 32px
        font-weight: 300
      p
        font-size: 20px
        line-height: 27px
        strong
          font-family: ff-meta-serif-web-pro, serif
          font-weight: 300
          font-size: 36px
          line-height: 55px
          font-style: italic

.promo-popup
  width: 480px
  margin-left: -240px
  font-family: proxima-nova, sans-serif

.promo-popup
  color: #000
  p
    margin: 0
  .first-line
    font-family: ff-meta-serif-web-pro, serif
    font-size: 39px
    line-height: 32px
    font-weight: normal
  .first-line-div
    margin: 30px auto
    width: 108px
    height: 0
    border-top: 1px solid #000
  .second-line, .third-line
    font-size: 20px
    font-weight: 200
    letter-spacing: 0.5px
    margin-bottom: 12px
    &.no-wrap
      height: 44px
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
    input[type=email]
      width: 100%
  .fourth-line
    font-weight: 100
    letter-spacing: 0.5px
    margin-bottom: 10px
    font-size: 18px
    color: gray

  .fat-border
    margin: 0
  .thin-border
    margin: 0
    border: none
    padding: 30px
    text-align: center
  .code
    width: 200px
    padding: 10px
    border: 1px solid #000
    font-size: 28px
    line-height: 32px
    font-weight: 300
    margin: 10px auto

.modal
  overflow-y: auto

.modal-open
  overflow: auto
