.hurry
  height: 34px
  line-height: 34px
  color: #fff
  font-size: 16px
  text-align: center
  background: #1d2226
  font-family: "ff-meta-serif-web-pro", serif
  font-style: italic
  font-weight: 300
  position: relative
  span.codes
    display: block
    position: absolute
    right: 0
    height: 34px
    font-size: 12px
    color: #f2f2f2
    border-left: 1px solid #f2f2f2
    top: 0
    padding: 0 12px
    font-style: normal
    font-family: proxima-nova, sans-serif
  span.ends
    font-size: 18px
    font-style: italic
    text-decoration: underline
.no-hurry
  background: url("./images/theme/border-bg1.png") repeat-x 0 bottom
  margin-bottom: 12px
  height: 12px
  clear: both
.weekly_sale
  font-family: "ff-meta-serif-web-pro", serif
  .wall-sale
    width: 24%
    float: left
    height: 115px
    background: #fd3f3e
    color: #fff
    .featured
      height: 62px
      line-height: 65px
      font-size: 28px
      text-align: center
      font-weight: 300
      position: relative
      span
        position: absolute
        bottom: 0
        left: 40%
        width: 20%
        border-bottom: 1px solid #fff
    .wall-name
      font-family: "ff-meta-serif-web-pro", serif
      font-size: 18px
      text-align: center
      height: 18px
      width: 100%
      margin-top: 14px
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden
      font-style: italic
  .prints-sale
    width: 76%
    float: right
    height: 115px
    background: #f2f2f2
    color: #1d2226
    font-style: italic
    .column
      width: 25%
      float: left
      height: 115px
      text-align: center
      font-size: 18px
      position: relative
      &.c23
        width: 23%
      &.c20
        width: 20%
      &.c32
        width: 32%
      &.c25
        width: 25%
      .vline
        position: absolute
        top: 26px
        height: 49px
        width: 1px
        background-color: #1d2226
        right: 0
      .save
        font-size: 32px
        margin-top: 35px
        margin-bottom: 3px
        font-style: normal
      .indi
        font-size: 14px
        padding: 0 34px
        margin-top: 35px
        line-height: 12px
        .line
          width: 20%
          display: inline-block
          border-top: 1px solid #1d2226
@media (max-width: 1200px)
  .weekly_sale .prints-sale .column .indi
    padding: 0 15px
