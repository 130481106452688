#content.checkout .wrapper
  width: 924px
  margin: 0 auto

.page-title
  background: #ec4b4a
  height: 40px
  line-height: 40px
  padding: 0 17px
  margin-bottom: 9px
  h2
    font-weight: 200
    line-height: 40px
    font-size: 20px
    line-height: 40px
    color: #fff

.checkout .two-column-layout
  .column1
    width: 550px
    float: left
    background: #fff
    border: 1px solid #d8d8d8
  .column2
    float: left
    width: 370px
    border-left: 1px solid #d8d8d8
    position: relative
    margin-left: -1px

/*------ BTN RED -----

.yaadframe
  width: 100%
  border: none
  height: 200px

.checkout
  .promo-code
    label
      font-size: 12px
    .form2
      .textbox
        width: 128px
  .btn-promo
    line-height: 20px
    font-size: 12px
    padding: 4px 8px
    margin: 0
    border: 0
    border-radius: 0
    background: #ddd

.checkout .btn-red, .form2 .btn-red
  padding: 0 9px
  height: 20px
  line-height: 20px
  font-size: 12px
  font-style: normal
  box-shadow: none
  -moz-box-shadow: none
  -webkit-box-shadow: none

/*---- SHOPPING CART ----

.shopping-cart
  h2
    background: #a09083
    height: 28px
    line-height: 28px
    padding: 0 10px
    font-size: 12px
    font-weight: normal
    color: #fff
    margin: 0
  a.edit
    float: right
    font-size: 10px
    color: #fff
    text-decoration: underline
    &:hover
      text-decoration: none
  ul
    margin: 0
    padding: 0
  ul li
    background: url("./images/theme/border-bg1.png") repeat-x scroll 0 bottom
    list-style: none
    display: flex
    flex-direction: row
    .image
      border-right: 1px solid #d8d8d8
      width: 162px
      text-align: center
      margin-right: 22px
      display: flex
      .inner
        display: flex
        align-items: center
        justify-content: center
        // height: 154px
        // width: 162px
        flex: 1
        .product
          margin: 18px
    .details
      padding: 12px 12px 12px 0
      flex: 1
      h3
        font-size: 14px
        font-weight: normal
        color: #3b3b3b
        margin: 0 0 5px
        line-height: 16px
        font-family: "ff-meta-serif-web-pro", serif
        small
          display: block
          line-height: 1em
          font-style: italic
          font-size: 11px
          color: #3b3b3b
          a
            color: #3b3b3b
            text-decoration: underline
      p
        font-size: 11px
        line-height: 17px
        color: #8e8e8a
      label
        font-size: 11px
        line-height: 16px
        color: #8e8e8a
        display: block
        margin: 0
        input
          vertical-align: middle
          margin-top: 0
    p span.price
      color: #3b3b3b
      &.new-price
        color: #ec4b4a
      &.old-price
        text-decoration: line-through
    &.last
      background: none
      margin-bottom: 0
    .actions
      float: right
      font-size: 10px
      color: #8e8e8a
      padding: 25px 12px 0 0
      span
        margin: 0 5px
      a
        color: #8e8e8a
        text-decoration: underline
        &:hover
          text-decoration: none

.two-column-layout
  .column2 .shopping-cart ul
    overflow: auto
    margin-left: 10px
    margin-bottom: 0
  .column1 .shopping-cart ul
    padding: 0 10px
    margin-left: 0
    margin-bottom: 0

.shopping-cart ul li
  &.discount
    margin: -1px -11px 0
    border: 1px solid #3b3b3b
    background: none
    .banner
      padding: 3px 18px
      text-align: center
      color: #3b3b3b
      border-left: 1px solid #3b3b3b
      letter-spacing: 2px
      h4
        margin: 0
        padding: 0
        font-size: 15px
        font-weight: normal
      small
        display: block
        color: #3b3b3b
        font-size: 11px
    p
      padding: 3px 12px 0 12px
      margin: 0
      color: #3b3b3b
      &.last
        margin-bottom: 12px
        span
          font-weight: bold
      span
        font-size: 12px
        &.old
          text-decoration: line-through
        &.new
          color: #ec4b4a
      a
        color: #3b3b3b

.shopping-cart.narrow ul li
  &.discount
    margin: -1px 0 0 0
    .banner
      float: none
      border-left: none
      padding: 3px 12px 0 12px
      text-align: left

.shopping-cart ul li .details h3 small a:hover
  text-decoration: none

/*------ Form2 ---

.form2
  p
    margin-bottom: 0 !important
  label
    display: block
    color: #8e8e8a
    font-size: 12px
    line-height: 18px
  .textbox
    display: inline-block
    width: 312px
    height: 26px
    padding: 0 10px
    margin-right: 10px
    color: #3b3b3b
    font-size: 12px
    border: 1px solid #d8d8d8
    border-radius: 0
    -moz-border-radius: 0
    -webkit-border-radius: 0
    box-shadow: none
    -moz-box-shadow: none
    -webkit-box-shadow: none
    margin-bottom: 0
  p,div
    &.one-half .textbox
      width: 142px
    &.one-third .textbox
      width: 88px
  h4
    font-size: 14px
    font-weight: 200
    margin-bottom: 10px
    color: #3b3b3b
    margin-top: 0
  ul
    margin: 0
    padding: 0
    li
      list-style: none
      margin-bottom: 10px
  a
    color: #ec4b4a
    font-size: 10px
  p,div
    &.one-half
      float: left
      margin-right: 7px
      width: 162px
    &.one-third
      float: left
      margin-right: 7px
      width: 108px
    &.one-half select
      width: 162px
    &.one-third select
      width: 108px
  span.required
    color: #ec4b4a
  select
    border-radius: 0
    -moz-border-radius: 0
    -webkit-border-radius: 0
    height: 28px
  .required-fields
    display: block
    text-align: right
    font-size: 10px
    color: #ec4b4a
  h4 a
    font-size: 10px
    color: #ec4b4a
    margin-left: 10px
    text-decoration: underline
  label input
    margin-top: 0

.accordion-inner
  a.change
    color: #ec4b4a

.checkout
  .required-fields
    float: right
/*----- Signin form ---

#signin-form
  .email
    float: left
    width: 256px
    margin-right: 7px
    .textbox
      width: 236px
  .password
    float: left
    width: 158px
    margin-right: 7px
    .textbox
      width: 138px
  .btn-red
    float: left
    margin-top: 4px
  .existing-customer
    border-top: 1px solid #d8d8d8
    padding-top: 5px
    .btn-red
      margin-top: 26px

#payment-detail-form
  .billing-address
    border-top: 1px solid #d8d8d8
    padding-top: 18px
    margin-bottom: 10px
  .promotion-code
    margin-bottom: 25px
    h4
      margin-bottom: 10px
    input.submit
      top: 0 !important

#shipping-detail-form .btn-red, #payment-detail-form .btn-red
  float: right
  margin-right: 30px

/*------ Popup Box ------

.popup1
  position: absolute
  z-index: 1000
  left: 40%
  top: 30%
  background: #fff
  padding: 17px 10px 30px
  width: 313px
  box-shadow: 0 0 3px black
  -moz-box-shadow: 0 0 3px black
  -webkit-box-shadow: 0 0 3px black
  h2
    font-size: 18px
    font-weight: normal
    background: url("./images/theme/border-bg1.png") repeat-x 0 bottom
    margin-bottom: 14px
    padding-bottom: 10px
    padding-left: 20px
    line-height: normal
    margin-top: 0
  .content
    padding: 0 20px
    p
      font-size: 12px
      line-height: 20px
      color: #8e8e8a
      margin-bottom: 20px!important
      a
        color: #000
        font-size: 12px
  &.form2
    .textbox
      width: 250px
    .btn-red
      float: right
      box-shadow: none
      -moz-box-shadow: none
      -webkit-box-shadow: none
  .close-btn
    position: absolute
    width: 25px
    height: 25px
    z-index: 1200
    top: 5px
    right: 5px
    background: url("./images/theme/close-btn.jpg") repeat-x 0 bottom
    display: block

.shipping_form
  address
    color: #8e8e8a
    font-size: 12px
    line-height: 20px
    margin: 6px 20px

#cc-cvc
  width: 50px

span.more-about-shipping
  display: none

#user_use_shipping_address_as_billing
  margin-top: 2px

#signin-form
  .btn
    float: none
  .new-customer
    margin-bottom: 10px
  .existing-customer
    .btn-red
      margin-top: 0px

.place-columns
  .c-promo
    p.applied
      margin-top: 25px
.upper_text
  margin-bottom: 9px

.paying-with-credit-card
  float: right
  a
    color: #8e8e8a
    text-decoration: underline
    font-size: 12px

.affirm-promo-cart
  margin: 12px 0
  & > p
    display: flex
    justify-content: center
    align-items: baseline
    font-size: 13px
  a, span
    margin: 0 3px
    &:last-child
      margin-right: 0
  p
    color: #8e8e8a
    padding: 0
    margin: 0
    text-align: center
    a
      color: #8e8e8a
      text-decoration: underline
      font-weight: bold
