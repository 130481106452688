$storelink_width:        69px
$storelink_height:       80px

.store_item_link
  width: $storelink_width
  height: 45px
  position: absolute
  background: rgba(0, 0, 0, 0.8)
  font-size: 12px
  color: #fff
  border: 1px solid #fff
  &.remove-item-bg
    background: rgba(100, 100, 100, 0.8)
  &.work
    top: 13%
    left: 2%
  &.wall
    top: 3%
    left: 2%
  a, span
    display: block
    text-align: center
    line-height: 14px
    height: 14px
    color: #fff
    padding-top: 8px
