/*
 *Cloud Zoom CSS. (c)2012 Star Plugins.

/* CSS for lens

.cloudzoom-lens
  border: none
  border: 1px solid #888888
  width: 100px
  height: 100px
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4)
  cursor: crosshair
  z-index: 9999

/* CSS for zoom window.

.cloudzoom-zoom
  border: 1px solid #888888
  width: 500px
  height: 200px
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4)
  z-index: 9999

/* CSS for zoom window in 'inside' mode.

.cloudzoom-zoom-inside
  border: none
  box-shadow: none
  z-index: 9999

/* CSS for captions

.cloudzoom-caption
  display: none
  /* CSS captions should be hidden initially
  text-align: left
  background-color: #000
  color: #fff
  font-weight: bold
  padding: 10px
  font-family: sans-serif
  font-size: 11px

/* A blank image

.cloudzoom-blank
  background-image: url("./images/blank.png")

/* The animated ajax loading image

.cloudzoom-ajax-loader
  background: transparent
  width: 32px
  height: 32px
