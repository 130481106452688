@use "arts/assets/variables" as variables;

$hp-desktop-margin: 0 calc(0.33px + 2.8vw);

@mixin hp-margin {
  & {
    margin: 0 variables.$mobile-padding;
  }

  @media (min-width: variables.$w-1-mobile) {
    margin: $hp-desktop-margin;
  }

  @media (min-width: variables.$hp-max) {
    margin: 0 72px
  }
}

@mixin no-decoration {
  & {
    text-decoration: none;
  }
  &:hover, &:focus {
    text-decoration: none;
  }
}

.alice-carousel {
  ul, li {
    padding: 0;
    margin: 0;
  }
}

.hpv2 {
  margin: 0 auto;
  width: 100%;
  max-width: variables.$hp-max;
  font-family: variables.$proxima-font-family;

  p {
    margin: 0;
  }

  .topwide {
    .alice-carousel__dots {
      margin: calc(1.0px + 1.76vw) 0;
    }

    button {
      display: block;
      outline: none;
      border: none;
      background: transparent;
      height: 80px;
      width: 60px;
      position: absolute;
      top: 37%;
      img {
        height: 100%;
      }
    }

    .alice-carousel__prev-btn button {
      left: 0;
    }

    .alice-carousel__next-btn button {
      right: 0;
    }

    @media (max-width: variables.$hp-break) {
      .alice-carousel__prev-btn, .alice-carousel__next-btn {
        display: none;
      }
    }

    // 2560 x 1280 = 1:2
    // 900 x 1177 = 1:0.7646559

    a {
      display: block;
      width: 100%;
      position: relative;
      height: 0;
      padding-bottom: 130%;

      @include no-decoration;

      @media (min-width: variables.$hp-break) {
        padding-bottom: 50%;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }

  .arrivals {
    @include no-decoration;

    @include hp-margin;
    & {
      display: flex;
      flex-direction: column;

      .text {
        width: 100%
      }

      .img {
        width: 100%;
        img {
          width: 100%;
        }
      }

      @media (max-width: variables.$hp-break) {
        .text {
          width: 30%;
          margin: 0 auto;
        }
      }

      @media (min-width: variables.$hp-break) {
        flex-direction: row;
        justify-content: space-between;
        .text {
          width: calc(3.67px + 17.51vw);
          height: calc(2.33px + 17.64vw);
        }

        .img {
          width: calc(3.0px + 75.0vw);
        }
      }

      @media (min-width: variables.$hp-max) {
        .text {
          width: 452px;
          height: 454px;
        }

        .img {
          width: 1923px;
        }
      }
    }
  }

  .edited {
    @include hp-margin;
    & {
      display: flex;
      margin-top: 25px;

      h2, p {
        text-align: center;
      }

      h2 {
        padding: 0;
        margin: 0;
        font-weight: normal;
        font-size: 17px;
        margin-bottom: 9px;
      }

      p {
        font-size: 12px;
        line-height: 13px;
        padding: 0 9px;
      }

      @media (max-width: variables.$hp-break) {
        flex-wrap: wrap;

        section {
          flex-basis: 50%;

          &:last-child {
            flex-basis: 100%;
            margin-top: 12px;

            p {
              padding: 0 18px;
            }
          }
        }

        .border.last {
          background: #000;
          height: 1px;
          width: 46px;
          margin: 18px auto 0
        }

        p {
          span.d {
            display: none;
          }
        }
      }

      @media (min-width: variables.$hp-break) {
        margin-top: calc(2.33px + 3.39vw);
        padding: calc(-5.0px + 4.3vw) 0 0 0;

        section {
          width: 33.33%
        }

        .border {
          width: calc(-0.33px + 0.13vw);
          height: calc(49.0px + 6.45vw);
          background: #d3cfc7;
        }

        h2 {
          font-size: calc(1.33px + 1.43vw);
          text-transform: uppercase;
          margin-bottom: 0;
        }

        p {
          padding: calc(9.33px + 1.43vw) calc(1.33px + 3.78vw) calc(-12.33px + 4.23vw);
          font-size: calc(6.0px + 0.78vw);
          line-height: calc(6.67px + 0.91vw);
          span.m {
            display: none;
          }
        }
      }

      @media (min-width: variables.$hp-max) {
        padding: 105px 0 96px 0;
        h2 {
          font-size: 38px;
        }
        p {
          font-size: 26px;
          padding: 46px 98px 31px;
          line-height: 30px;
        }
        .border {
          width: 3px;
          height: 215px;
        }
      }
    }
  }

  .video-banner {
    @include hp-margin;
    @media (min-width: variables.$hp-break) {
      margin-top: calc(2.33px + 3.39vw);
    }

    & {
      // margin-top: 25px;
      margin-top: calc(2.33px + 3.39vw);
    }

    div {
      width: 50%;
      margin: 0 auto;

      video {
        width: 100%;
      }

      @media (max-width: 1400px) {
        width: 75%;
      }

      @media (max-width: 1100px) {
        width: 100%;
      }
    }
  }


  .small-banners {
    @include hp-margin;

    & {
      margin-top: 25px;
    }

    a {
      display: block;
      padding-bottom: 12px;
      @include no-decoration;
    }
    img {
      width: 100%;
    }

    p {
      color: #000;
    }

    p {
      font-size: 12px;
      letter-spacing: 0.24px;
    }

    @media (min-width: variables.$hp-break) {
      padding-bottom: 0;
      margin-top: calc(1.0px + 2.73vw);

      display: grid;
      grid-template-columns: calc(1.33px + 44.6vw) calc(1.33px + 44.6vw);
      grid-column-gap: calc(0.0px + 4.88vw);
      grid-row-gap: calc(-1.67px + 2.6vw);

      p {
        font-size: calc(7.33px + 0.65vw); //14-24
        padding: 0;
        margin: 0;
      }
    }

    @media (min-width: variables.$hp-max) {
      grid-template-columns: 1143px 1143px;
      grid-column-gap: 125px;
      grid-row-gap: 65px;

      h3 {
        padding: 0;
        margin-top: 32px;
        font-size: 36px;
        letter-spacing: 1.44px;
      }

      p {
        font-size: 24px;
        padding: 0;
        margin: 0;
      }
    }
  }

  .small-banners, .video-banner {

    h3 {
      font-weight: normal;
      font-size: 14px;
      padding: 0;
      margin: 12px 0 0 0;
      text-transform: uppercase;
      letter-spacing: 0.28px;
      color: #000;
    }

    @media (min-width: variables.$hp-break) {
      h3 {
        padding: 0;
        margin: calc(2.0px + 1.17vw) 0 0 0; // 14-32
        font-size: calc(2.67px + 1.3vw); // 16-36
        letter-spacing: calc(-0.43px + 0.07vw); // 0.32-1.44
      }
    }
    @media (min-width: variables.$hp-max) {
      grid-template-columns: 1143px 1143px;
      grid-column-gap: 125px;
      grid-row-gap: 65px;

      h3 {
        padding: 0;
        margin-top: 32px;
        font-size: 36px;
        letter-spacing: 1.44px;
      }
    }
  }

  .featured {
    display: block;
    @include hp-margin;
    @include no-decoration;

    img {
      width: 100%;
    }
    @media (min-width: variables.$hp-break) {
      margin-top: calc(-1.67px + 2.6vw);
    }
    @media (min-width: variables.$hp-max) {
      margin-top: 65px;
    }
  }

  .premier {
    @include hp-margin;
    // & {
    //   margin-top: 14px;
    // }

    p {
      text-align: center;
    }


    p.l {
      font-size: 14px;
      text-align: center;
      padding: 25px 0;
    }

    @media (min-width: variables.$hp-break) {
      // margin-top: calc(2.33px + 3.39vw);
      p.l {
        padding: calc(-13.0px + 5.27vw) calc(-67.0px + 11.72vw) 0;
        font-size: calc(2.67px + 1.3vw);
        line-height: calc(-2.0px + 2.15vw);
      }

      .divider {
        height: calc(0.67px + 0.13vw);
        width: calc(-10.0px + 11.72vw);
        background: #000;
        margin: calc(-2.0px + 3.91vw) auto calc(-13.0px + 5.27vw) auto;
      }
    }
    @media (min-width: variables.$hp-max) {
      p.l {
        padding: 122px 233px 0;
        font-size: 36px;
        line-height: 53px;
        margin: 98px auto 122px auto;
      }

      .divider {
        height: 4px;
        width: 290px;
      }
    }
  }

  .picks {
    @include hp-margin;
    @include no-decoration;
    & {
      display: block;
      height: 0;
      position: relative;
      padding-bottom: 65.79%;
    }

    img {
      width: 100%;
    }

    .pick {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    @media (min-width: variables.$hp-break) {
      margin-top: calc(-2.67px + 2.99vw);
      padding-bottom: 35.51%;

    }

    @media (min-width: variables.$hp-max) {
      margin-top: 74px;
    }
  }
}
